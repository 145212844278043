:root {
  --color-primary: theme('colors.primary');
  --color-secondary: theme('colors.secondary');
  --base-base-200: theme('colors.base-200');
  --neutral: theme('colors.neutral');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.input[readonly] {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 0.5;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  color: oklch(0.214679 0.02969 277.747495 / 0.4);
  color: var(--fallback-bc, oklch(var(--bc) / 0.4));
  cursor: not-allowed;
}

.toggle[readonly] {
  --tw-bg-opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

select[readonly] {
  --tw-bg-opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.mockup-code pre::before {
  content: none;
}

.active {
  color: var(--color-primary);
}

.custom-calendar .rbc-event {
  background-color: var(--color-primary);
  color: white;
}

.custom-calendar .rbc-event.rbc-selected {
  background-color: var(--color-primary);
  opacity: 0.8;
  transition: opacity 0.2s;
  font-weight: bold;
}

.custom-calendar .rbc-month-view {
  background-color: var(--base-base-200);
}

.custom-calendar .rbc-day-bg,
.custom-calendar .rbc-date-cell {
  background-color: var(--base-base-200);
}

.custom-calendar .rbc-current {
  font-weight: bold;
  border: 2px solid var(--color-primary);
}

.custom-calendar .rbc-button-link {
  color: var(--color-primary);
}

.custom-calendar .rbc-off-range-bg {
  background-color: var(--neutral);
}
