@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');

#subtitles-preview::cue {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: calc(var(--branch-width) / 72);
  font-family: 'Lato', sans-serif;
  text-shadow: 1px 1px 1px black;
  padding: 0.25em;
  line-height: 1.25;
  margin-bottom: calc(var(--branch-height) / 72);
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-text-shadow: 1px 1px 1px black;
  -moz-text-shadow: 1px 1px 1px black;
  text-shadow: 1px 1px 1px black;
}
